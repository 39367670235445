import ColourBox from './components/ColourBox.vue'
import Clients from './components/Clients.vue'
import Definition from './components/Definition.vue'
import FeatureFull from './components/FeatureFull.vue'
import FeatureSplit from './components/FeatureSplit.vue'
import HeroComponent from './components/Hero.vue'
import VStack from './components/VStack.vue'
import Search from './components/Search.vue'
import IconText from './components/IconText.vue'
import PricingTable from './components/PricingTable.vue'
import PricingLarge from './components/PricingLarge.vue'


export const REGISTERED_COMPONENTS = [
  {
    component: HeroComponent,
    name: 'Image Hero',
    inputs: [
      {
        name: 'ctaText',
        type: 'string',
        defaultValue: 'Understanding ESG is the first step to being part of the solution.',
      },
      {
        name: 'ctaLink',
        type: 'string',
        defaultValue: 'Read more',
      },
      {
        name: 'ctaUrl',
        type: 'url',
      },
      {
        name: 'title',
        type: 'string',
        defaultValue: 'ESG SOLUTIONS',
        required: true,
      },
      {
        name: 'subtitle',
        type: 'string',
        defaultValue: 'UPDATE. UPHOLD. UPLIFT.',
      },
      {
        name: 'text',
        type: 'string',
        defaultValue: 'Proactive. Simple. Affordable. Valuable.',
      },
      {
        name: 'button1Text',
        type: 'string',
        defaultValue: 'Get started',
      },
      {
        name: 'button1Url',
        type: 'url',
      },
      {
        name: 'button2Text',
        type: 'string',
      },
      {
        name: 'button2Url',
        type: 'url',
      },
    ],
  },
  {
    component: VStack,
    name: 'Vertical Stack',
    inputs: [
      {
        name: 'icon',
        type: 'text',
        enum: ['i-mdi-earth', 'i-mdi-crowd', 'i-mdi-bank']
      },
      {
        name: 'title',
        type: 'string',
      },
      {
        name: 'subTitle',
        type: 'string',
      },
      {
        name: 'description',
        type: 'richText',
      },
    ]
  },
  {
    component: Clients,
    name: 'Clients',
    inputs: [
      {
        name: 'heading',
        type: 'string',
        defaultValue: 'Trusted by the world’s most innovative teams',
        required: true,
      },
      {
        name: 'logos',
        type: 'list',
        helperText: 'Client logos for light theme',
        subFields: [
          {
            name: 'name',
            type: 'string',
            required: true,
          },
          {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['svg'],
            required: true,
          },
        ],
      },
      {
        name: 'darkLogos',
        type: 'list',
        helperText: 'Client logos for dark theme',
        subFields: [
          {
            name: 'name',
            type: 'string',
            required: true,
          },
          {
            name: 'image',
            type: 'file',
            allowedFileTypes: ['svg'],
            required: true,
          },
        ],
      },
    ]
  },
  {
    component: Definition,
    name: 'Word Definition',
    inputs: [
      {
        name: 'word',
        type: 'string',
      },
      {
        name: 'pronunciation',
        type: 'string',
      },
      {
        name: 'definition',
        type: 'richText',
      },
    ]
  },
  {
    component: FeatureSplit,
    name: 'Half Feature',
    defaultChildren: [],
    inputs: [
      {
        name: 'image',
        type: 'file',
        allowedFileTypes: ['jpg', 'png', 'svg'],
        required: true,
      },
      {
        name: 'heading',
        type: 'string',
        required: true,
      },
      {
        name: 'content',
        type: 'richText',
        required: true,
      },
      {
        name: 'direction',
        friendlyName: 'Show Image on',
        type: 'string',
        required: true,
        defaultValue: 'left',
        enum: ['left', 'right']
      },
    ]
  },
  {
    component: ColourBox,
    name: 'Color Box',
    inputs: [
      {
        name: 'title',
        type: 'string',
        required: true,
      },
      {
        name: 'text',
        type: 'longText',
        required: true,
      },
      {
        name: 'background',
        type: 'color',
        defaultValue: '#FDABA2',
      },
    ]
  },
  {
    component: Search,
    name: 'Org Search',
  },
  {
    component: IconText,
    name: 'Icon with text',
    inputs: [
      {
        name: 'icon',
        type: 'file',
        allowedFileTypes: ['jpg', 'png', 'svg'],
        required: true,
      },
      {
        name: 'iconDark',
        type: 'file',
        allowedFileTypes: ['jpg', 'png', 'svg'],
        required: true,
      },
      {
        name: 'title',
        type: 'string',
      },
      {
        name: 'text',
        type: 'richText',
      },
    ]
  },
  {
    component: PricingTable,
    name: 'Plan comparison table',
    inputs: [
      {
        name: 'heading',
        type: 'string',
        required: true,
        defaultValue: 'Compare Plans'
      },
    ]
  },
  {
    component: PricingLarge,
    name: 'Pricing Large Feature',
    inputs: [
      {
        name: 'heading',
        type: 'string',
        required: true,
        defaultValue: 'Solo, small or large company? We have you covered.'
      },
      {
        name: 'firstPlan',
        type: 'reference',
        model: 'plan',
        required: true,
      },
      {
        name: 'firstBenefits',
        type: 'list',
        subFields: [
          {
            name: 'feature',
            type: 'string',
            required: true,
          },
        ]
      },
      {
        name: 'firstDisadvantage',
        type: 'list',
        subFields: [
          {
            name: 'feature',
            type: 'string',
            required: true,
          },
        ]
      },
      {
        name: 'firstTerms',
        type: 'richText',
      },
      {
        name: 'firstLink',
        type: 'url',
      },
      {
        name: 'firstLinkText',
        type: 'string',
        defaultValue: 'Start free trial',
      },
      {
        name: 'secondPlan',
        type: 'reference',
        model: 'plan',
        required: true,
      },
      {
        name: 'secondBenefits',
        type: 'list',
        subFields: [
          {
            name: 'feature',
            type: 'string',
            required: true,
          },
        ]
      },
      {
        name: 'secondTerms',
        type: 'richText',
      },
      {
        name: 'secondLink',
        type: 'url',
      },
      {
        name: 'secondLinkText',
        type: 'string',
        defaultValue: 'Start free trial',
      },
    ]
  },
]