<template>
    <div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
        <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
            <h2 class="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200">
                {{ heading }}
            </h2>
        </div>

        <div class="relative xl:w-10/12 xl:mx-auto">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
                <div v-for="(plan, key) in plans">
                    <div :class="(key === 'second' ? 'shadow-xl shadow-gray-200 dark:shadow-gray-900/[.2] p-5' : 'p-4') + ' relative z-10 bg-white border rounded-xl md:p-10 dark:bg-dark dark:border-gray-700'">
                        <div v-if="plan.plan.value">
                            <h3 v-if="plan.plan.value.name" class="text-xl font-bold text-gray-800 dark:text-gray-200">{{ plan.plan.value.name }}</h3>
                            <span v-if="key === 'second'" class="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-gray-800 text-white py-1.5 px-3 dark:bg-white dark:text-gray-800">Most popular</span>

                            <div class="mt-5">
                                <span class="text-6xl font-bold text-gray-800 dark:text-gray-200">{{ plan.plan.value.data.price }}</span>
                                <span class="ms-3 text-gray-500">{{ plan.plan.value.data.priceAfterText }}</span>
                            </div>
                        </div>

                        <div v-if="key === 'second'" class="mt-5 py-4 py-0">
                            <ul class="text-base grid sm:grid-flow-col gap-y-2 sm:grid-cols-2 sm:gap-x-6" :style="'grid-template-rows: repeat(' + roundUp(secondBenefits.length / 2) + ', minmax(0, 1fr))'">
                                <li v-for="ben in plan.benefits" class="flex align-middle space-x-3">
                                    <span class="h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                                        <icon class="i-heroicons-check" />
                                    </span>
                                    <span class="text-gray-800 dark:text-gray-200">
                                        {{ ben.feature }}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div v-else class="mt-5 grid sm:grid-cols-2 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                            <ul class="space-y-2 text-base">
                                <li v-for="ben in plan.benefits" class="flex space-x-3">
                                    <span class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                                        <icon class="i-heroicons-check" />
                                    </span>
                                    <span class="text-gray-800 dark:text-gray-200">
                                        {{ ben.feature }}
                                    </span>
                                </li>
                            </ul>

                            <ul class="space-y-2 text-base">
                                <li v-for="dis in plan.disadvantage" class="flex space-x-3">
                                    <span class="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                                        <icon class="i-heroicons-x-mark" />
                                    </span>
                                    <span class="text-gray-800 dark:text-gray-200">
                                        {{ dis.feature }}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                            <div v-html="plan.terms"></div> 

                            <div class="flex justify-end">
                                <a :href="plan.link" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                    {{ plan.linkText }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="hidden md:block absolute top-0 end-0 translate-y-16 translate-x-16">
                <svg class="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
                <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
                <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
                </svg>
            </div>

            <div class="hidden md:block absolute bottom-0 start-0 translate-y-16 -translate-x-16">
                <svg class="w-56 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" stroke-width="7" stroke-linecap="round"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    heading: {
        type: String,
        required: true
    },
    firstPlan: {
        type: Object,
        required: true
    },
    firstBenefits: {
        type: Array,
    },
    firstTerms: {
        type: String,
        required: true
    },
    firstLink: {
        type: String,
        required: true
    },
    firstLinkText: {
        type: String,
        required: true
    },
    firstDisadvantage: {
        type: Array,
    },
    secondPlan: {
        type: Object,
        required: true
    },
    secondBenefits: {
        type: Array,
    },
    secondTerms: {
        type: String,
        required: true
    },
    secondLink: {
        type: String,
        required: true
    },
    secondLinkText: {
        type: String,
        required: true
    },
})

const plans = {
    first: {
        plan: props.firstPlan,
        benefits: props.firstBenefits,
        terms: props.firstTerms,
        link: props.firstLink,
        linkText: props.firstLinkText,
        disadvantage: props.firstDisadvantage,
    },
    second: {
        plan: props.secondPlan,
        benefits: props.secondBenefits,
        terms: props.secondTerms,
        link: props.secondLink,
        linkText: props.secondLinkText,
    },
}
</script>