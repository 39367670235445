<template>
    <div class="max-w-screen-2xl mx-auto">
        <RenderContent
            v-if="canShowContent"
            model="page"
            :content="content"
            :api-key="apiKey"
            :customComponents="REGISTERED_COMPONENTS"
        />
  </div>
</template>

<script setup lang="ts">
import { REGISTERED_COMPONENTS } from '~/builder-page.ts';
import { RenderContent, fetchOneEntry, isPreviewing } from '@builder.io/sdk-vue/vue3'
import '@builder.io/sdk-vue/vue3/css'
import { useUserStore } from '~/stores/user'

const { logout } = useUserStore()
if (useUserStore().hasUser) {
    logout()
}

const apiKey = useAppConfig().builderKey
const route = useRoute()
const { data: content } = await useAsyncData('builderData', () =>
    fetchOneEntry({
        model: 'page',
        apiKey: apiKey,
        userAttributes: {
            urlPath: route.path,
        },
    })
);

const canShowContent = content || isPreviewing()

if (content) {
    useSeoMeta({
        title: content.value.data.title,
        description: content.value.data.description,
        keywords: content.value.data.keywords,
    })
}
</script>