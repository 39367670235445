<template>
    <div class="relative">
    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 md:py-14 lg:py-20 mx-auto">
        <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2 class="text-2xl font-bold md:text-3xl md:leading-tight dark:text-white">{{ heading }}</h2>
        </div>

        <div class="hidden lg:block sticky top-0 start-0 py-2 backdrop-blur-md">
            <div :class="'grid grid-flow-col auto-cols-fr gap-6'">
                <div>
                    <span class="font-semibold text-lg text-gray-800 dark:text-gray-200">
                        Features
                    </span>
                </div>

                <div v-for="plan in plans" class="col-span-1">
                    <span class="font-semibold text-lg text-gray-800 dark:text-gray-200">
                        {{ plan.data.name }}
                    </span>
                    <p class="mt-2 text-sm text-gray-500">
                        {{ plan.data.price + ' ' + plan.data.priceAfterText }} 
                    </p>
                </div>
            </div>
        </div>

        <div v-for="(features, category) in features" class="space-y-4 lg:space-y-0">
            <ul  class="grid lg:grid-flow-col lg:auto-cols-fr lg:gap-6">
                <li class="lg:py-3">
                    <span class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        {{ category }}
                    </span>
                </li>

                <li v-for="plan in plans" class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
            </ul>

            <ul v-for="feature in features" class="grid lg:grid-flow-col lg:auto-cols-fr lg:gap-6">
                <li class="pb-1.5 lg:py-3">
                    <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-gray-200">
                        {{ feature.name }}
                    </span>
                </li>

                <li v-for="plan in plans" class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-gray-700">
                    <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                        <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-gray-200">
                            {{ plan.name }}
                        </span>
                        <span class="text-sm text-gray-800 dark:text-gray-200">
                            <Icon v-if="isFeatureIncluded(feature, plan)" class="i-heroicons-check h-5 w-5" />
                            <Icon v-else class="i-heroicons-minus h-5 w-5" />
                        </span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="hidden lg:block mt-6">
            <div class="grid grid-flow-col auto-cols-fr gap-6">
                <div>
                </div>

                <div v-for="plan in plans" class="col-span-1">
                    <a class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                        Get started
                    </a>
                </div>
            </div>
        </div>

        <!-- Button Group -->
        <div class="mt-8 md:mt-12 flex justify-center items-center gap-x-3">
        <p class="text-sm text-gray-500 dark:text-gray-400">
            Need a custom plan?
        </p>

        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            Contact us
        </button>
        </div>
        <!-- End Button Group -->
    </div>
    </div>
    <!-- End Comparison Table -->
</template>

<script setup lang="ts">
defineProps({
    heading: {
        type: String,
        required: true
    },
})

import { ref } from 'vue'
import { fetchEntries } from '@builder.io/sdk-vue/vue3'
const appConfig = useAppConfig()

const plans: object[] = ref([])
const features: object[] = ref([])

plans.value = await fetchEntries({
    model: 'plan',
    apiKey: appConfig.builderKey,
}).then((data) => { return data.results })

const limit = 100
let offset = 0
const results = await fetchEntries({
    model: 'plan-comparison',
    apiKey: appConfig.builderKey,
    limit: limit,
    offset: offset,
}).then((data) => { return data.results })

while(results.length === (limit + offset)) {
    offset += pages.length
    results.push( ... (await fetchEntries({
        model: 'plan-comparison',
        apiKey: appConfig.builderKey,
        limit: limit,
        offset: offset,
    }).then((data) => { return data.results })))
}
features.value = results.reduce((group, feature) => {
    const category = feature.data.category
    group[category] = group[category] ?? []
    group[category].push(feature)
    return group
}, {})

function isFeatureIncluded(feature, plan) {
    for (const p of feature.data.plans) {
        if (p.plan.id === plan.id) {
            return true
        }
    }
    return false
}
</script>