<template>
    <section class="w-screen ml-half mt-12 lg:mt-24">
        <div class="bg-mint text-white -skew-y-1">
            <div class="container mx-auto skew-y-1">
                <div class="flex flex-col items-center py-10 text-center lg:py-20">
                    <div class="w-full px-4 xl:w-1/2 lg:px-0">
                        <div class="mb-8">
                            <h2 class="text-3xl lg:text-4xl font-bold mb-3">
                                Looking for a organisation?
                            </h2>
                        </div>

                        <div class="mb-10 text-gray-600">
                            <div class="relative">
                                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <div v-if="loading" class="animate-spin inline-block w-6 h-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <Icon v-else class="i-heroicons-magnifying-glass" />
                                </div>

                                <input id="search-input" type="search" name="search" autocomplete="off" placeholder="Type organisation name here" class="p-4 pl-10 rounded-xl w-full border-gray-100"
                                    @input="searchOrganisations" @focus="openD()" @click="(e) => e.stopImmediatePropagation()" @keydown="(e) => e.stopImmediatePropagation()"
                                >
                            </div>

                            <div id="search-wrap" class="hs-dropdown relative inline-flex w-full [--strategy:absolute]">
                                <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10 mt-5 bg-white w-full rounded-xl shadow-xl overflow-hidden" aria-labelledby="hs-dropdown-unstyled">
                                    <div v-for="org in orgs" @click="setSelected(org)" class="w-full flex p-3 pl-4 items-center hover:bg-gray-300 rounded-lg cursor-pointer">
                                        <div class="font-bold text-lg">{{ org.OrganisationName }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="text-lg">
                            <p>
                                Can't find your organisation?<br class="sm:hidden">
                                <a href="#" class="border-b border-white pb-1">
                                    Click here to send them a link
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="selected" class="px-5 max-w-5xl mx-auto mt-20">
            <template v-if="badges.length > 0">

                <h3 class="mb-4 text-3xl">Badges</h3>
                <div class="flex items-center -mx-3 mb-4">
                    <Icon class="i-heroicons-chevron-left shrink-0 w-6 h-6 mb-8" />
                    <div class="flex gap-4 overflow-x-auto flex-nowrap no-scrollbar">
                        <figure v-for="i in 10" class=" w-36 shrink-0">
                            <img :src="badges[0].Badge64String" :alt="badges[0].Description" />
                            <figcaption>{{ badges[0].Name }}</figcaption>
                        </figure>
                    </div>
                    <Icon class="i-heroicons-chevron-right shrink-0 h-6 w-6 mb-8" />
                </div>
            </template>

            <h3 class="mb-4 text-3xl">Organisation Information</h3>
            <dl>
                <div v-for="(dV, dK) in selected" class="columns-2 mb-2 text-lg">
                    <dt class="">{{ camelToSpace(dK) }}</dt>
                    <dd class="">{{ dV }}</dd>
                </div>
            </dl>
        </div>
    </section>
</template>

<script setup lang="ts">
import { debounce } from 'perfect-debounce'

let orgs = ref({})
let loading = ref(false)
const selected = ref()
const badges = ref()

const dSearch = debounce(async () => {
    const term = document.getElementById('search-input').value
    if (term !== '') {
        try {
            const result = await useApi().organisation.search(term)
            orgs.value = result.Organisations
        } finally {
            loading.value = false
            openD()
        }
    } else {
        orgs.value = {}
    }
}, 250)

const searchOrganisations = (e) => {
    loading.value = true
    dSearch()
    if (e.target.value === '') loading.value = false
}

function setSelected(org: object) {
    badges.value = org.Badges
    selected.value = {...org}
    delete selected.value.Badges
}

function openD() {
    window.HSDropdown.open(document.getElementById('search-wrap'))
}
</script>